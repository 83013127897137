.layout {
  background-color: var(--background);
}

.layout[data-camperlab] {
  font-family: var(--font-camperlab);
  --font-primary: var(--font-camperlab);
}

html[lang='ko-KR'] .layout[data-camperlab],
html[lang='ko-KR'] body .layout[data-camperlab] {
  font-family: var(--font-primary);
  --font-primary: var(--font-primary);
}

.layout footer {
  overflow: hidden;
  /* margin-top: 4rem; */
  z-index: 1;
  position: relative;
}

body[data-domain='nnormal'] .layout footer {
  margin-top: 0px;
}

.headerNav :global .tnm__title div {
  z-index: 10000;
}

body[data-domain='camperlab'] .headerNav :global .tnm__title div {
  z-index: auto;
}

.headerNav :global .headroom {
  z-index: 99 !important;
  background-color: white;
}

body:not([data-domain='camperlab']) .headerNav :global .tnm {
  background-color: white;
}

body[data-domain='camperlab'] .headerNav :global .headroom {
  background-color: transparent;
}

body[data-domain='camperlab'] .headerNav :global .tnm {
  border-bottom: 0px;
}

body[data-domain='camperlab'] .headerNav :global div[id^='search-test'] input,
body[data-domain='camperlab'] .headerNav :global div[id^='search-test'] input::placeholder {
  color: var(--color-black);
}

body[data-domain='camperlab'] .headerNav :global div[id^='search-test'] {
  border-color: var(--color-black);
}

.headerNav :global .tnm--mobile .tnm__modal {
  z-index: 10000;
}

body[data-domain='camperlab'] .headerNav :global .tnm--mobile .tnm__modal #lab-cart-icon {
  display: none;
}

.breadcrumbsBottom {
  margin-top: 50px;
}

.backdrop::before {
  visibility: hidden;
  opacity: 0;
  content: ' ';
  position: fixed;
  width: 100%;
  height: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
  background-color: hsla(0, 0%, 7%, 0.36);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  z-index: 50;
  -webkit-transition:
    opacity 0.3s,
    visibility 0s linear 0.3s;
  transition:
    opacity 0.3s,
    visibility 0s linear 0.3s;
}

body[data-domain='camperlab'] .backdrop::before {
  top: 0px;
}

.backdropVisible::before {
  visibility: visible;
  opacity: 1;
  -webkit-transition:
    opacity 0.3s ease,
    visibility 0s;
  transition:
    opacity 0.3s ease,
    visibility 0s;
}

.scrollArrow {
  border: none;
  border-radius: 50px;
  box-shadow: 0px 3px 5px 1px grey;
  background-color: var(--color-white);
  position: fixed;
  bottom: 2%;
  width: 27px;
  height: 27px;
  left: calc(50% - (27px / 2));
  z-index: 97;
  cursor: pointer;
  font-size: 12px;
  outline: none;
  padding: 0px;
}

.userCScontainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
  font-size: ModerneRegular;
  font-size: 0.8em;
  width: 100%;
}

.countryToSendSelect {
  width: 170px;
}

.countryToSendSelect * {
  border: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
}

.countryToSendSelect :global .ant-select,
.countryToSendSelect :global .ant-select-selector {
  width: 100%;
}

.countryToSendSelect :global .ant-select:not(.ant-select-customize-input) :global .ant-select-selector {
  border: none !important;
}

.userCSLink {
  margin-left: 0.5%;
  padding: 1px;
  text-decoration: none;
  background-image: linear-gradient(#d9291c, #d9291c);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 2px;
  transition: background-size 0.3s;
}

.userCSLink:hover,
.userCSLink:focus {
  background-size: 100% 3px;
}

.infoCVC {
  margin-right: 0.5%;
  margin-bottom: 3px;
}

.scrollArrow:focus,
.scrollArrow:active {
  outline: none;
}

@media (max-width: 991px) {
  .headerNav {
    min-height: 46px;
  }

  .headerNav :global .tnm {
    top: 0px;
    padding-top: 0.5em;
  }
  body[data-domain='camperlab'] .headerNav :global .tnm {
    padding-top: 0rem;
  }
  body[data-domain='camperlab'] .headerNav {
    min-height: auto;
  }

  body[data-domain='camperlab'] .headerNav :global .tnm {
    padding-top: 0px;
  }
}

@media (max-width: 767.5px) {
  .headerNav :global .tnm-footer__list > li:first-child > div > p {
    display: none;
  }
}

.userLine {
  border-right: solid black 1px;
  padding-right: 10px;
}
