.modalGiftBagMask {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  opacity: 0;
  transition: opacity .7s;
}
.modalGiftBagMaskVisible {
  display: block;
  transition: opacity .7s;
}
.closeSvg {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 2;
}
.closeSvg:hover {
  cursor: pointer;
  opacity: .7;
}
.modalContent {
  display: none;
  grid-template-columns: auto 1fr;
  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 1001;
  background: white;
  opacity: 0;
  transition: opacity .7s;
}
.content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 40px 40px;
}

.contentPadded {
  padding: 0 40px 40px;
}
.svgWrapper {
  text-align: center;
}
.title {
  font-size: 1.7em;
  margin-bottom: 0px;
  text-align: center;
}
.subtitle {
  font-size: 1rem;
  margin: 24px auto 0;
  max-width: 275px;
  text-align: center;
}
.Nnormal .subtitle {
  margin: 24px 0px 0px;
}

.modalWrapper {
  overflow-x: hidden;
}
.modalGiftBag {
  overflow-x: hidden;
}
.modalWrapper :global .ant-modal-body {
  padding: 0px;
}

.cancel:hover {
  cursor: pointer;
}

.clickBelow {
  margin-bottom: 0;
  text-align: center;
}
.photo {
  height: 100%;
  /* max-width: 420px; */
}

.photoCalendar {
  height: 100%;
  max-width: 420px;
  display: flex;
  align-items: center;
}

.photo > img {
  object-fit: cover;
}
.photoImg {
  width: 100%;
}

.closeButton {
  border: none;
  cursor: pointer;
  display: grid;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  position: absolute;
  top: 0;
  right: 0px;
}

.closeButton svg g {
  stroke: white !important;
}

.TH {
  justify-content: flex-start;
  margin-top: 2em;
  margin-left: 2em;
}

.TH .title, .TH .subtitle {
  text-align: left;
  align-items: flex-start;
}

.TH .title {
  font-weight: bold;
  font-size: 16px;
}

.TH .subtitle {
  font-size: 14px;
  margin-top: 1.5em;
}

.Nnormal {
  justify-content: flex-start;
  margin-top: 2em;
  margin-left: 2em;
}

.Nnormal .title, .Nnormal .subtitle {
  text-align: left;
  align-items: flex-start;
}

.Nnormal .title {
  font-weight: bold;
  font-size: 16px;
  font-family: var(--font-secondary);
}

.Nnormal .subtitle {
  font-size: 14px;
  margin-top: 1.5em;
}

@media(max-width: 767.5px) {
  .modalWrapper :global .ant-modal {
    max-width: 100vw;
    margin: 0;
    min-width: 100vw !important;
    padding-bottom: 0;
    position: absolute;
    top: 0;
    min-height: 100vh;
  }
  .modalWrapper :global .ant-modal-content {
    /*
    margin-top: 16px;
    margin-left: 16px;
    margin-right: 16px;
    */
    position: fixed;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
  .photo {
    /* display: none; */ 
  }
  .subtitle {
    font-size: var(--title-size);
  } 
  .modalContent {
    /* display: grid; */
    grid-template-columns: 1fr 1fr;
  }
  .photoImg {
    width: 100%;
    height: auto;
  }
  .title {
    line-height: 1;
    padding-top: 1.5em;
    font-size: 1.5em;
  }
  .content {
    padding: 0px 20px;
  }
  /* .modalContent {
    display: flex;
    flex-flow: column;
  }
  .footerPhoto {
    display: flex;
    flex: 1 1 auto;
    background-image: url('/assets-new/newsletter.jpg');
    background-size: cover;
  }
  .genderSelector :global .ant-radio-group {
    display: inline-flex;
  }
  .privacyWrapper {
    width: 272px;
    margin: auto;
    text-align: left;
  } */
}
@media(max-width: 500px) {
  .modalContent {
    grid-template-columns: 50% 50%;
  }
  .content {
    padding-left: 20px;
    padding-bottom: 20px;
    margin-top: 25px;
    margin-left: 0px;
  }
  .subtitle {
    word-break: break-word;
  }
  .Nnormal .subtitle {
    font-size: 12px;
    margin-top: 1em;
  }
}

@media(min-width: 768px) {
  .content {
    background-color: var(--color-white);
    -webkit-box-flex: 2;
    -ms-flex: 2;
    flex: 2;
    /* min-height: 636px; */
    padding: 40px 20px;
    max-width: 280px;
  }

  .subtitle {
    max-width: 438px;
    font-size: 1.2rem;
  }

  .modalContentUpdate {
    padding: 0 60px;
    padding-top: 48px;
  }
  .modalWrapper :global .ant-modal-content {
    position: fixed;
    right: 0px;
    bottom: 0px;
  }
  .modalWrapper :global .ant-modal {
    margin: unset !important;
  }
  .photo {
    max-width: 280px;
  }
}
.modalContentVisible {
  display: grid;
  transition: opacity .7s;
}