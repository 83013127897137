.wrapper {
  margin-right: 1.3em;
  display: flex;
  align-items: center;
}
.container {
  width: 100%;
  position: relative;
  min-width: 15vw;
  font-family: var(--font-moderne);
}
.container :global .ant-select {
  padding-left: 0px;
  padding-right: 0px;
  width: 100%;
}
.container :global .ant-select-selector {
  padding-left: 0px;
  padding-right: 0px;
  height: 28px;
}
.container :global .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none;
  border-bottom: 1px solid rgb(202, 198, 198);
  background-color: transparent;
  padding-left: 0px;
  padding-right: 0px;
}
.container :global .ant-select-selection-placeholder {
  text-transform: uppercase;
  font-size: 0.75em;
  color: rgb(223, 223, 223);
}
.container :global .ant-select-selection-search-input {
  padding-left: 0px;
  padding-right: 0px;
}
.container :global .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
}
.container :global .ant-select-single .ant-select-selector .ant-select-selection-search {
  left: 0px;
}
.container :global .ant-select-arrow {
  top: 50%;
  pointer-events: auto;
}
.searchIcon {
  display: block;
  position: absolute;
  left: 30px;
  z-index: 1;
  bottom: 9px;
  padding: 6px;
  cursor: pointer;
}

.searchInput :global .ant-select-clear {
  opacity: 1;
  background: transparent;
  width: 5px;
  height: 12px;
  zoom: 1.5;
}
.container :global .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  padding-left: 0px;
  padding-right: 0px;
}
.searchIconV1 {
  display: inline;
}
.inputV1 {
  border: none;
  display: inline-block;
  padding-left: 0px;
}
.inputV1:focus-visible {
  outline: none;
}
.inputV1::placeholder {
  font-family: var(--font-moderne);
  text-transform: uppercase;
  font-size: 0.75em;
  color: rgb(223, 223, 223);
}
.searchV1 {
  border-bottom: 1px solid rgb(202, 198, 198);
  padding: 0px 3px;
  display: block;
}
.visible {
  display: block;
}
.invisible {
  display: none;
}
@media screen and (max-width: 768px) {
  .container {
    position: absolute;
    top: 50px;
    width: 90%;
    left: 5%;
    height: 0px;
  }

  body[data-domain='nnormal'] .container {
    top: 54px;
  }
  .container.hidden :global .ant-select {
    top: -150px;
  }

  body[data-domain='nnormal'] .searchIcon {
    left: 40px;
  }

  .container [class*='searchInput'] {
    display: none;
  }
}
@media screen and (min-width: 769px) {
  .searchIcon {
    display: none;
  }
}
@media screen and (max-width: 1199px) {
  .searchWaiting {
    border-bottom: none;
  }
  .searchWaiting input {
    display: none;
  }

  body[data-domain='nnormal'] .inputV1 {
    display: none;
  }

  body[data-domain='nnormal'] .searchV1 {
    border-bottom: 0px;
  }
}

@media screen and (min-width: 1200px) {
  #search-test-b > div {
    display: inline-block;
  }
}
