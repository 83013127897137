.protectionData {
  text-align: center;
}

.protectionData :global .ant-modal-content {
  padding: 20px 24px 24px;
}

.protectionData :global .ant-modal-body {
  padding: 24px 0;
}

.protectionData :global .ant-modal-body >div {
  font-size: var(--description-size);
}

.protectionData h2 {
  line-height: 1.1em;
  font-size: 1.5em;
  margin-bottom: 24px;
}

@media screen and (max-width: 767.5px) {
  .protectionData  {
    height: 100%;
    width: 100%;
    min-height: 100%;
    min-width: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }
  
  .protectionData :global .ant-modal-content {
    padding: 40px 24px 24px;
    height: 100%;
    width: 100%;
    min-height: 100%;
    min-width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    overflow: auto;
  }
  
  .protectionData :global .ant-modal-body {
    padding: 24px;
  }
}