.modalTitle {
  font-size: var(--regular-size);
  line-height: 1.1em;
  color: var(--color-primary);
  text-transform: capitalize;
}

.modalSubtitle {
  font-size: var(--description-size);
  margin-bottom: 0px;
  color: var(--color-primary);
}

.buttonContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 10px 0px;
}

.collectionButton {
  padding: 10px;
  color: var(--color-white);
  background-color: var(--color-black);
  border-color: var(--color-black);
  text-align: center;
  width: 120px;
  height: 40px;
  font-size: 16px;
  margin: 2%;
}

.collectionButton:hover {
  background-color: var(--color-white);
  color: var(--color-black);
}

.ctaHref {
  padding: 0.5em;
  position: relative;
  top: 20px;
  border: 1px solid black;
}

@media screen and (min-width: 1024px) {
  .modalSubtitle {
    margin-top: 24px;
  }
}
