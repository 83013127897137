.closeSvg {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 2;
}
.closeSvg:hover {
  cursor: pointer;
  opacity: 0.7;
}

.contentPadded {
  padding: 20px 40px;
}

.formErrorHelper {
  font-size: var(--title-size);
  color: var(--color-error);
  margin-top: 0.5em;
  margin-bottom: 0px;
}
.emailForm {
  display: grid;
  gap: 24px;
  margin: 40px auto 0;
  width: 272px;
}
.emailForm :global .ant-form-item,
.additionalForm :global .ant-form-item {
  margin: 0px;
}
.emailForm :global input {
  border-top-style: none;
  border-right-style: none;
  border-left-style: none;
  box-shadow: none !important;
  padding-left: 0px;
  padding-right: 0px;
}

body[data-domain="nnormal"] .emailForm :global input {
  border-top-style: solid;
  border-right-style: solid;
  border-left-style: solid;
  box-shadow: none !important;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
body[data-domain="nnormal"] .emailForm :global .ant-form-item-label {
  font-family: var(--font-secondary);
}
.emailForm
  :global
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-search,
.additionalForm
  :global
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  left: 0px;
}
.emailForm :global .ant-picker {
  width: 100%;
  border-top-style: none;
  border-right-style: none;
  border-left-style: none;
  box-shadow: none !important;
  padding-left: 0px;
  padding-right: 0px;
}

.emailForm :global .ant-form-item-explain {
  bottom: -2em;
  font-size: var(--description-size);
  position: absolute;
}

.additionalForm {
  display: grid;
  gap: 12px;
  margin: 20px auto 0;
  width: 272px;
}
.additionalForm :global .ant-form-item-label {
  padding-bottom: 0px;
}
body[data-domain="nnormal"] .additionalForm :global .ant-form-item-label {
  padding-bottom: 4px;
  font-family: var(--font-secondary);
}
.additionalForm :global input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  display: block;
  font-size: 0.875em;
  min-width: 100%;
}

body[data-domain="nnormal"]
  .additionalForm
  :global
  input:not(.ant-input-focused) {
  border: 1px solid var(--color-black);
  /* box-shadow: none !important; */
  padding-left: 11px;
  padding-right: 11px;
  font-size: 1rem;
}

body[data-domain="nnormal"] .additionalForm :global input:hover {
  border-color: var(--color-black);
}

.formPhone
  :global
  .ant-select:not(.ant-select-customize-input)
  :global
  .ant-select-selector {
  border-bottom: 1px solid black;
}

.formPhone :global input {
  border-bottom: 1px solid black;
  padding-top: 0px;
  padding-bottom: 0px;
}
.phoneWrapper :global input {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  display: flex;
  align-items: center;
}
.phoneWrapper :global input,
.phoneWrapper :global .ant-select-selector,
.phoneWrapper :global .ant-select {
  height: 35px !important;
}

body[data-domain="camper"] .emailInput {
  display: flex;
}
body[data-domain="camper"] .emailInput input {
  border-bottom: 1px solid black !important;
  padding-top: 6px;
  padding-bottom: 6px;
}

div.selector :global .ant-select-selector,
.datePicker :global .ant-picker {
  border: none;
  border-bottom: 1px solid black !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 0.875em;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 6px 0;
  width: 100%;
}

body[data-domain="nnormal"] .additionalForm :global .ant-select-selector {
  border-color: var(--color-black);
}

body[data-domain="nnormal"] .datePicker :global .ant-picker {
  border: 1px solid var(--color-black);
  font-size: 1em;
  padding: 3px 11px;
}
body[data-domain="nnormal"]
  .datePicker
  :global
  .ant-picker
  .ant-picker-input
  input {
  border: none;
}

.datePicker :global .ant-picker-suffix {
  margin-left: 0px;
  right: 0px;
  position: absolute;
}

.datePicker input {
  font-size: 1em;
}

.additionalForm :global .ant-form-item-control {
  flex: auto !important;
}

body[data-domain="nnormal"] .genderSelector {
  align-self: end;
}

.genderSelector :global .ant-radio-group {
  display: grid;
  gap: 32px;
  grid-template-columns: auto auto;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  white-space: nowrap;
}
.genderSelector :global .ant-radio {
  transform: scale(1);
}
.genderSelector :global .ant-radio .ant-radio-inner {
  height: 26px;
  width: 26px;
  border-color: var(--color-black);
}
.genderSelector :global .ant-radio .ant-radio-inner::after {
  width: 12px;
  height: 12px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
body[data-domain="nnormal"]
  .genderSelector
  :global
  .ant-radio
  .ant-radio-inner {
  display: flex;
  align-items: center;
  justify-content: center;
}


.genderSelector :global .ant-radio-wrapper > span:not(.ant-radio) {
  margin: auto;
  margin-left: 4px;
  font-size: var(--title-size);
}
.modalWrapper {
  overflow-x: hidden;
}

.modalWrapper :global .ant-modal-body {
  padding: 0px;
}
.footerPhoto {
  display: none;
}

.subtitleUpdate {
  max-width: none;
}
.updateItem {
  text-align: center;
}
.updateBtn {
  height: auto;
  margin-top: 6px;
  padding: 10px 20px;
}
body[data-domain="nnormal"] .updateBtn {
  font-family: var(--font-secondary);
  line-height: normal;
}
.updateBtn:hover {
  border-color: var(--color-black);
  background-color: var(--color-black);
  color: var(--color-white);
}
.cancel {
  font-size: 0.75em;
  margin-bottom: 0;
  text-align: center;
  text-decoration: underline;
}
.cancel:hover {
  cursor: pointer;
}

.privacyCheckbox > span > a {
  margin-left: 3px;
}
p.infoLink {
  font-size: var(--description-size);
  color: var(--color-black);
  text-decoration: underline;
}

a.boldLink {
  color: var(--color-black);
  font-family: var(--font-primary);
  text-decoration: none;
}

a.boldLink:hover {
  color: #919191;
}

p.infoLink:hover {
  color: var(--color-black);
  text-decoration: underline;
  cursor: pointer;
}

p.infoLink:visited {
  color: var(--color-black);
  text-decoration: underline;
}
.privacyCheckbox {
  position: relative;
  vertical-align: middle;
  display: flex;
  align-items: flex-start;
  font-size: var(--description-size) !important;
}
.privacyCheckbox > span:nth-child(2) {
  line-height: 1.2;
}
.privacyCheckbox :global .ant-checkbox {
  height: 24px;
  width: 24px;
  min-width: 24px;
  margin: 0px;
}
.withMargin :global .ant-checkbox {
  margin-right: 16px;
}

.privacyCheckbox :global .ant-checkbox > .ant-checkbox-input {
  height: 24px;
  width: 24px;
}

.privacyCheckbox :global .ant-checkbox-inner::after {
  left: 31.5%;
}

.privacyCheckbox :global .ant-checkbox-inner {
  border: 1px solid black;
  content: "";
  display: inline-block;
  height: 24px;
  left: 0;
  position: absolute;
  width: 24px;
}

.privacyCheckbox > span:nth-child(2) {
  max-width: 272px;
}

.submitItem {
  text-align: center;
}

.submitBtn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: white;
  display: inline-block;
  font-size: 1rem;
  margin-top: 16px;
  padding: 10px 20px;
  border-color: var(--color-black);
  height: 50px !important;
}
body[data-domain="nnormal"] .submitBtn {
  font-family: var(--font-secondary);
  line-height: normal;
}

.clickBelow {
  margin-bottom: 0;
  text-align: center;
}

.selligentModal :global .ant-spin-container {
  height: 550px;
}

.selligentIframe {
  height: 550px;
  width: 100%;
  border: none;
}

.closeButton {
  border: none;
  cursor: pointer;
  display: grid;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  position: absolute;
  top: 0;
  right: 0px;
}

.newsletterPhone {
  display: flex;
}

body[data-domain="nnormal"] .newsletterPhone :global .ant-select-selector {
  border-top-style: solid !important;
  border-left-style: solid !important;
}

.newsletterPhone :global .ant-select-selector {
  border-top-style: none !important;
  border-right-style: none !important;
  border-left-style: none !important;
  box-shadow: none !important;
}

/* .newsletterPhone :global input {
  height: 100%;
} */

.newsletterPhone :global input {
  box-shadow: none !important;
}

.modalWrapperThanks :global .ant-modal-content {
  text-align: center;
  overflow: auto;
}

@media (max-width: 767.5px) {
  .modalWrapper:not(.modalWrapperThanks) :global .ant-modal {
    max-width: 100vw;
    margin: 0;
    min-width: 100vw !important;
    padding-bottom: 0;
    position: absolute;
    top: 0;
    min-height: 100vh;
  }
  .modalWrapper :global .ant-modal-content {
    margin-top: 16px;
    margin-left: 16px;
    margin-right: 16px;
  }
  .contentPadded {
    padding-top: 40px;
  }
  .emailForm :global .ant-form-item-control-input-content {
    text-align: center;
  }
  .footerPhoto {
    display: flex;
    flex: 1 1 auto;
    background-image: url("/assets-new/newsletter.jpg");
    background-size: cover;
  }
  .genderSelector :global .ant-radio-group {
    display: inline-flex;
  }
  .privacyWrapper {
    width: 272px;
    margin: auto;
    text-align: left;
  }
  .privacyCheckbox {
    text-align: left;
  }
  .privacyCheckbox {
    display: inline-flex;
  }
  .privacyCheckbox {
    max-width: 272px;
  }
  .additionalForm label {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .modalWrapper:not(.modalWrapperThanks):not(.selligentModal)
    :global
    .ant-modal {
    min-width: 710px !important;
  }

  .emailForm {
    max-width: inherit;
  }

  .additionalForm {
    gap: 10px 40px;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 40px;
    width: 520px;
    max-width: inherit;
  }

  .modalContentUpdate {
    padding: 0 60px;
    padding-top: 48px;
  }

  .updateItem,
  .cancel {
    grid-column: 1 / 3;
  }

  .subtitleThanks {
    font-size: 1rem;
  }
}

@media screen and (width <= 575px) {
  .modalWrapper :global .ant-form-item-control,
  .modalWrapper :global .ant-form-item-label {
    flex: 1 !important; /* en serio la gente de antd son criminales de guerra, ara de repente meten un flex: 0 para width < 575px -_- */
  }
}
