.errorText {
  color: var(--color-error);
}
.accountMenu :global .ant-dropdown-menu-item {
  cursor: auto;
  word-break: break-word;
  white-space: pre-wrap;
  max-width: 260px;
  padding: 16px;
}
body[data-domain='nnormal'] .menuDiv {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.menuP {
  margin-bottom: 0px;
}
.welcomeTitle {
  text-transform: uppercase;
  font-size: var(--description-size);
  background-color: var(--color-white);
  border-bottom: 1px solid var(--color-divider);
  margin-bottom: 1em;
  padding: 0px;
  padding-bottom: 5px;
}
.menuLink {
  cursor: pointer;
  text-transform: capitalize;
}
.anOrder {
  /* margin-top: 40px; */
  margin-bottom: 0px;
}

.title {
  text-align: start;
  font-size: 18px;
  font-weight: 400;
}
body[data-domain='nnormal'] .title {
  font-family: var(--font-secondary);
}
.description {
  text-align: start;
  font-size: var(--title-size);
}
body[data-domain='nnormal'] .description {
  margin-top: 6px;
  font-family: var(--font-primary);
}
body[data-domain='camperlab'] .description {
  font-size: 0.875rem;
}

a.description {
  font-weight: 600;
  text-decoration: underline;
  color: var(--color-black);
}
.loginBtn {
  text-align: start;
  height: fit-content;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: white;
  border: none;
  border-radius: 0;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  border: 1px solid var(--color-grey-light);
  color: black;
  font-size: 1rem;
  padding: 8px 20px;
  line-height: initial;
}
body[data-domain='nnormal'] .loginBtn {
  font-family: var(--font-secondary);
}
.loginBtn:hover {
  background-color: var(--color-black);
  color: var(--color-white);
}
/* [TODO]: forzado a pisar estilos de patterson... han metido un estilo global span que nos chafa el nuestro */
.accountMenu :global button > span {
  padding: 0;
}
.accountMenu :global button > span:hover:after {
  display: none;
}
.logoutTrigger > span:hover:after {
  display: none;
}
/* end of override */
.accountIcon {
  display: grid;
}
.accountIcon:hover {
  cursor: pointer;
}
.accountMenu :global .ant-btn-secondary {
  width: 100%;
  margin-top: 16px;
  margin-bottom: 40px;
  background-color: var(--color-black);
  color: var(--color-white);
}
.accountMenu :global .ant-btn-secondary:hover {
  background-color: var(--color-white);
  color: var(--color-black);
}
.accountMenu {
  padding: 16px 0px 10px 8px;
  overflow: auto;
  width: 250px;
  display: block;
  position: absolute;
  top: calc(100% - 16px);
  right: 0;
  z-index: 10000;
  font-family: var(--font-moderne);
}
.ordersBtn {
  background-color: white;
  color: black;
  border: 1px solid black;
  padding: 8px 20px;
  text-align: start;
  height: fit-content;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  font-size: 1rem;
  padding: 8px 20px;
  line-height: initial;
  margin-top: 16px;
}
body[data-domain='nnormal'] .ordersBtn {
  font-family: var(--font-secondary);
}
body[data-domain='nnormal'] .ordersBtn[data-lang='ca'] {
  font-size: 0.875rem;
}
.ordersBtn:hover {
  background-color: var(--color-black);
  color: var(--color-white) !important;
}
.ordersBtn:hover > span {
  color: var(--color-white) !important;
}
.menuOverlay {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  padding: 20px 20px 40px;
  background-color: var(--color-white);
  cursor: auto;
}
body[data-domain='nnormal'] .menuOverlay {
  padding-bottom: 20px;
}
body[data-domain='nnormal'] .menuOverlay p {
  margin-bottom: 0;
}
.menuOverlayLogedIn {
  padding-bottom: 20px;
}
.logoutTrigger {
  margin-bottom: 0px;
  padding-top: 20px;
  text-align: end;
  border-top: 1px solid var(--color-divider);
}
.logoutTrigger > span {
  cursor: pointer;
  opacity: 0.5;
}
.label {
  font-family: var(--font-moderne);
  font-size: var(--description-size);
  position: relative;
  top: 0px;
  text-transform: uppercase;
}
body[data-domain='nnormal'] .label {
  font-family: var(--font-primary);
  text-transform: none;
}
body[data-domain='nnormal'] .label::after {
  border-top-width: 1px;
}

body[data-domain='camperlab'] .label {
  position: static;
  text-transform: uppercase;
}

.myAccountFormWrapper {
  text-align: center;
  margin-top: 26px;
}
.myAccountTitle {
  font-size: 24px;
  margin-bottom: 16px;
  margin-top: 16px;
  line-height: 1.3em;
}
body[data-domain='nnormal'] .myAccountTitle {
  font-family: var(--font-secondary);
}
.accountDescription {
  font-size: var(--title-size);
  margin-bottom: 16px;
}
.createAccountBtn {
  height: auto;
  padding: 6.5px 15px;
  margin-top: 8px;
  margin-bottom: 0px;
}
body[data-domain='nnormal'] .createAccountBtn {
  font-family: var(--font-secondary);
}
.createAccountBtn:hover {
  background-color: var(--color-grey-light);
  border-color: var(--color-grey-light);
  color: var(--color-black);
}
.createAccountDetails {
  font-size: var(--description-size);
  margin: 40px auto 16px;
  max-width: 240px;
}
.modalWrapper :global .ant-modal {
  max-width: 360px;
}
.myAccountloginForm :global .ant-form-item {
  margin-bottom: 8px;
}
.forgotAccountP {
  font-size: var(--description-size);
  margin-top: 16px;
  margin-bottom: 8px;
  cursor: pointer;
  text-decoration: underline;
}
.cancelBtn {
  font-size: var(--description-size);
  cursor: pointer;
  text-decoration: underline;
}
.loggedInWrapper {
  margin-top: 1rem;
}

body[data-domain='camperlab'] .accountIcon {
  background: none !important;
  height: auto !important;
  width: auto !important;
}

body[data-domain='camperlab'] .label {
  font-family: var(--font-primary) !important;
  font-size: 0.75rem;
}

@media (min-width: 1439.5px) {
  .accountIcon > span:first-child {
    display: none;
  }
}

@media (max-width: 1439.5px) {
  .myAccountFormWrapper {
    margin-top: 0px !important;
  }
  body:not([data-domain='camperlab']) .label {
    display: none;
  }
  .accountIcon {
    height: 19px;
    text-align: center;
    width: 18px;
  }
}

@media (max-width: 1200px) {
  body[data-domain='camper'] .label {
    text-decoration: underline;
    text-transform: capitalize;
  }
}

@media (max-width: 991.5px) {
  .accountIcon {
    height: 24px;
    text-align: center;
    width: 27px;
  }
}

@media (max-width: 767.5px) {
  .modalWrapper :global .ant-modal {
    max-width: 100%;
    height: 100%;
    margin: 0;
    min-width: 100% !important;
  }
  .modalWrapper :global .ant-modal-content {
    max-width: 100vw;
    height: 100;
  }
  .modalWrapper :global .ant-modal-body {
    height: 100vh;
  }
  .myAccountFormWrapper {
    max-width: 360px;
    margin: 0 auto;
  }
  .accountIcon {
    display: flex;
    width: auto;
    background-position: left;
    background-size: auto 20px;
  }
  .label {
    position: relative;
    text-transform: capitalize;
    left: 30px;
    top: 4px;
    font-size: 0.75em;
    display: flex;
  }
}
