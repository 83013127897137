.personalShopperModal :global .ant-modal-content {
  height: 500px;
  width: 310px;
}

.personalShopperModal {
  margin-top: 118px !important;
}

@media screen and (min-width: 768px) {
  .personalShopperModal :global .ant-modal-content {
    height: 480px;
    width: 750px;
  }
}

@media screen and (min-width: 992px) {
  .personalShopperWrapper {
    top: 7px;
  }
}

@media screen and (min-width: 1024px) {
  .personalShopperWrapper {
    position: relative;
    left: 12px;
    top: 4px;
    margin-right: 4px;
    margin-left: 0px;
  }
}
