.breadcrumbWrapper {
    font-size:  var(--small-size);
    padding: 10px 16px 10px 16px;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    row-gap: 15px;
}
.breadcrumbWrapper span {
    display: inline-flex;
}

.breadcrumbWrapper :global span.ant-breadcrumb-link {
    background-color: var(--color-grey-light);
    justify-content: space-between;
    margin: 0px 5px 0px 0;
    padding: 6px 16px;
}

body[data-domain="nnormal"] .breadcrumbWrapper :global span:last-child  span.ant-breadcrumb-link,
body[data-domain="nnormal"] .breadcrumbWrapper :global span:last-child  span.ant-breadcrumb-link a {
    background-color: var(--color-primary);
    color: var(--color-white);
}
.breadcrumbWrapper .ant-breadcrumb-link {
    margin: 0;
}