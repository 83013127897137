
p.infoLink {
  font-size: var(--description-size);
  color: var(--color-black);
  text-decoration: underline;
}

a.boldLink {
  color: var(--color-black);
  font-family: var(--font-primary);
  text-decoration: none;
}

a.boldLink:hover {
  color: #919191;
}

p.infoLink:hover {
  color: var(--color-black);
  text-decoration: underline;
  cursor: pointer;
}

p.infoLink:visited {
  color: var(--color-black);
  text-decoration: underline;
}

.privacyCheckbox > span > a {
  margin-left: 3px;
}


.privacyCheckbox {
  position: relative;
  vertical-align: middle;
  display: flex;
  align-items: flex-start;
  font-size: var(--description-size) !important;
}
.privacyCheckbox :global .ant-checkbox {
  height: 24px;
  width: 24px;
  min-width: 24px;
  margin: 0px;
}
.withMargin :global .ant-checkbox {
  margin-right: 16px;
}

.privacyCheckbox :global .ant-checkbox > .ant-checkbox-input {
  height: 24px;
  width: 24px;
}

.privacyCheckbox :global .ant-checkbox-inner::after {
  left: 31.5%;
}

.privacyCheckbox :global .ant-checkbox-inner {
  border: 1px solid black;
  content: "";
  display: inline-block;
  height: 24px;
  left: 0;
  position: absolute;
  width: 24px;
}

.privacyCheckbox > span:nth-child(2) {
  max-width: 272px;
}

.privacyAgreement {
  text-align: left;
}

.privacyAgreement > a {
  color: var(--color-black);
  font-weight: bold;
}

@media(max-width: 767.5px) {
  .privacyWrapper {
    width: 272px;
    margin: auto;
    text-align: left;
  }
}