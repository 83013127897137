.uploadMessage {
  background-color: #eee;
  width: 100%;
  padding: 0.5em 1em;
  margin-bottom: 1em;
}
.uploadBtn {
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  -webkit-box-shadow: 0 2px 0 rgba(0,0,0,.015);
  box-shadow: 0 2px 0 rgba(0,0,0,.015);
  cursor: pointer;
  -webkit-transition: all .3s cubic-bezier(.645,.045,.355,1);
  -moz-transition: all .3s cubic-bezier(.645,.045,.355,1);
  transition: all .3s cubic-bezier(.645,.045,.355,1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 2.4px 15px;
  font-size: 16px;
  border-radius: 0;
  color: #000;
  background: #fff;
  border: 1px solid #d9d9d9;
  align-items: center;
}
.uploadBtn > span {
  margin-left: 8px;
}
.remove {
  display: none;
  cursor: pointer;
}
.ol {
  list-style-type: none;
  padding-inline-start: 0px;
}
.ol > li {
  display: flex;
  align-items: center;
  margin-top: 4px;
  justify-content: space-between;
}
.ol > li > div > img {
  margin-right: 4px;
}
.error {
  color: var(--color-error);
}
.ol > li:hover {
  background-color: #f5f5f5;
  -webkit-transition: background-color .3s;
  -moz-transition: background-color .3s;
  transition: background-color .3s;
}
.ol > li:hover > .remove {
  display: flex;
}
.error {
  background-color: var(--color-white) !important;
  border: 1px solid var(--color-error);
  color: var(--color-error);
  font-size: var(--description-size);
  padding: 4px 8px;
}